import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/index.vue";

Vue.use(VueRouter);

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      name: "登录",
    },
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: Home,
    children: [
      {
        path: "/user/index",
        name: "user",
        component: () => import("@/views/user/index"),
        meta: {
          name: "用户列表",
        },
      },
      {
        path: "/yaoyueRecord/index",
        name: "yaoyueRecord",
        component: () => import("@/views/yaoyueRecord/index"),
        meta: {
          name: "邀约记录",
        },
      },
      {
        path: "/course/index",
        name: "course",
        component: () => import("@/views/course/index"),
        meta: {
          name: "课程记录",
        },
      },
      {
        path: "/expert/index",
        name: "expert",
        component: () => import("@/views/expert/index"),
        meta: {
          name: "专家列表",
        },
      },
      {
        path: "/news/index",
        name: "news",
        component: () => import("@/views/news/index"),
        meta: {
          name: "新闻管理列表",
        },
      }
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
