import Vue from "vue";
import { Message, Loading } from "element-ui";

/**
 * 调用接口数据
 * @param {*} url 接口url
 * @param {*} requestParams 相关参数
 * @param {*} method 方法名称
 * @param {*} options 其他参数
 * @returns
 */

export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}
/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 从sessionStorage中获取指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function getItem(key) {
  try {
    const value = sessionStorage.getItem(key);
    return isJSONString(value) ? JSON.parse(value) : value;
  } catch (error) {
    console.error(`${error}`);
    return null;
  }
}

/**
 * 将键值对存储到sessionStorage中
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function setItem(key, value) {
  try {
    const serializedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`${error}`);
  }
}

/**
 * 从sessionStorage中移除指定键的值
 * @param {*} length
 * @param {*} chats
 * @returns
 */
export function removeItem(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(` ${error}`);
  }
}

//检查字符串是否是JSON格式
function isJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * 重新加载主题
 * @param {*} themeName 主题名称
 * @returns
 */
export function loadTheme(themeName) {
  if (!themeName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("theme-style");
  if (link) {
    link.href = `/theme/${themeName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "theme-style";
    newLink.rel = "stylesheet";
    newLink.href = `/theme/${themeName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 重新加载风格
 * @param {*} styleName 风格名称
 * @returns
 */
export function loadStyle(styleName) {
  if (!styleName) return;
  const head = document.getElementsByTagName("head")[0];
  const link = document.getElementById("item-style");
  if (link) {
    link.href = `/style/${styleName}.css`;
  } else {
    const newLink = document.createElement("link");
    newLink.id = "item-style";
    newLink.rel = "stylesheet";
    newLink.href = `/style/${styleName}.css`;
    head.appendChild(newLink);
  }
}

/**
 * 统一封装消息提示，适用于简单提示
 * @param {*} message 消息内容
 * @param {*} type 消息类型
 * @param {*} options 其他参数
 */
export function message(message, type, options) {
  Message({
    type: type || "success",
    message,
    ...options,
  });
}

Vue.prototype.getItem = getItem;
Vue.prototype.setItem = setItem;
Vue.prototype.removeItem = removeItem;
Vue.prototype.loadTheme = loadTheme;
Vue.prototype.loadStyle = loadStyle;
Vue.prototype.message = message;

export default { randomString };
