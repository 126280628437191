import axios from "axios";
import config from "@/assets/api/config";
import { Message } from "element-ui";
const getAjax = async (url, requestParams, method) => {
  return new Promise((resolve) => {
    let params = {};
    let data = {};
    if (method === "get") {
      params = requestParams;
    } else {
      data = requestParams;
    }
    axios({
      method,
      url: config.base + url,
      responseType: "json", /// 设置相应头
      // `headers`是要发送的自定义请求头
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
      data,
      params,
    }).then((res) => {
      if (!res.data.success) {
        Message.error(res.data.errorMessage || '操作失败');
      } else {
        resolve(res.data);
      }
    });
  });
};
export default getAjax;
