<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" class="login-form" autocomplete="on" label-position="left">
      <div class="title-container">
        <h3 class="title">用户登录</h3>
      </div>

      <el-form-item prop="loginName">
        <span class="svg-container">
          <i class="el-icon-user-solid"></i>
        </span>
        <el-input ref="loginName" v-model="loginForm.loginName" placeholder="loginName" name="loginName" type="text"
          tabindex="1" autocomplete="on" />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock"></i>
        </span>
        <el-input :key="passwordType" ref="password" v-model="loginForm.loginPassword" :type="passwordType"
          placeholder="Password" name="password" tabindex="2" autocomplete="on" @keyup.native="checkCapslock"
          @blur="capsTooltip = false" @keyup.enter.native="handleLogin" />
      </el-form-item>
      <!-- <el-form-item prop="captchaCode" style="display:flex;align-items:center;">
        <span class="svg-container">
          <i class="el-icon-user-solid"></i>
        </span>
        <el-input ref="captchaCode" v-model="loginForm.captchaCode" placeholder="验证码" name="captchaCode" type="text"
          tabindex="1" autocomplete="on" style="width:415px" />
        <img :src="yzmUrl" alt="" style="width:auto;height:30px;position:absolute;right:10px;top:10px;"
          @click="changeYzm()">
      </el-form-item> -->
      <el-button :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        loginName: "",
        loginPassword: "",
        captchaId: "1234",
        captchaCode: '1234'
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      yzmUrl: ''
    };
  },
  mounted() {
    if (this.loginForm.loginName === "") {
      this.$refs.loginName.focus();
    } else if (this.loginForm.loginPassword === "") {
      this.$refs.password.focus();
    }
    // this.changeYzm();
  },
  methods: {
    changeYzm() {
      this.getAjax(
        "api/admin/login",
        {},
        "get"
      ).then(res => {
        this.yzmUrl = res.image;
        this.loginForm.captchaId = res.captchaId;
      })
    },
    handleLogin() {
      this.getAjax(
        "api/admin/login",
        { ...this.loginForm },
        "post"
      ).then((res) => {
        localStorage.setItem("token", res.data.tokenInfo.tokenValue);
        this.$router.push({
          name: "yaoyueRecord",
        });
      });
    },
  },
};
</script>

<style lang="less">
@bg: #283443;
@light_gray: #fff;
@cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: @cursor)) {
  .login-container .el-input input {
    color: @cursor;
  }
}

/* reset element-ui css */
.login-container {
  font-size: 14px;

  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: @light_gray;
      height: 47px;
      caret-color: @cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px @bg inset !important;
        -webkit-text-fill-color: @cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: @bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: @dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: @light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
}
</style>
